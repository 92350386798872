
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MeshSrvRes } from '../../model/MeshSrvRes';

interface Styling {
  logoImageSrc?: string
  logoContainerBackgroundColor?: string
}

// interface MeshSrvRes {
 
//   messageForIdentity?: string
//   styling?: Styling
// }

@Component({
  selector: 'app-ack-form',
  templateUrl: './ack-form.component.html',
  styleUrls: ['./ack-form.component.css']
})

export class AckFormComponent implements OnInit {

  fullHtml:boolean=false;

  messageForIdentity: SafeHtml = "";
  logoImageSrc: string = "";//"//img1.wsimg.com/isteam/ip/ca93572b-a1a1-4dc4-b52a-4486517d2e97/DSC_02141.jpg/:/";
  logoContainerBackgroundColor:string = "";//'#222C56';
  
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private zHttp: HttpService,
    private router: Router,
    private domSanitizer:DomSanitizer,
    ) { }

  ngOnInit() {
    
    const arrayOfUrlPathSegments = this.router.url.split('/');
    const pinpointRef = arrayOfUrlPathSegments[1];

    // console.log(`ack-form ngOnInit running`);
    // console.log(`ack-form has pinpointRef: ${pinpointRef}`);

    
    this.zHttp.guthrie('ackform').subscribe((meshSvrRes: MeshSrvRes)=>{
      
      console.log(`ack-form has meshSvrRes = ${JSON.stringify(meshSvrRes,null,2)}`);

      // if (!!meshSvrRes.customRedirectURL){
        
      //   window.location.href=meshSvrRes.customRedirectURL;

      // }else{

        this.fullHtml=(meshSvrRes.messageForIdentity.indexOf('zuant-full-html')>-1);

        // this.messageForIdentity = meshSvrRes.messageForIdentity;
        this.messageForIdentity = this.domSanitizer.bypassSecurityTrustHtml(meshSvrRes.messageForIdentity);
        this.logoImageSrc = meshSvrRes.styling ? meshSvrRes.styling.logoImageSrc : "";
        this.logoContainerBackgroundColor = meshSvrRes.styling ? meshSvrRes.styling.logoContainerBackgroundColor : "";

      // }

    });
    
  }
}

