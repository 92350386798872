
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

//https://angular.io/guide/security#trusting-safe-values

interface Styling {
  logoImageSrc?: string
  logoContainerBackgroundColor?: string
}

interface MeshSrvRes {
  messageForIdentity?: string
  styling?: Styling
}

@Component({
  selector: 'app-ack-scan',
  templateUrl: './ack-scan.component.html',
  styleUrls: ['./ack-scan.component.css']
})

export class AckScanComponent implements OnInit {

  fullHtml:boolean=false;

  messageForIdentity: SafeHtml = "";
  // siteName: string="eewew";
  logoImageSrc: string = "";//"//img1.wsimg.com/isteam/ip/ca93572b-a1a1-4dc4-b52a-4486517d2e97/DSC_02141.jpg/:/";
  logoContainerBackgroundColor:string = "";//'#222C56';
  
  constructor(
    private domSanitizer:DomSanitizer,
    private http: HttpClient,
    private route: ActivatedRoute,
    private zHttp: HttpService,
    private router: Router,
    ) { }


  ngOnInit() {
    // console.log(`ack-scan ngOnInit running`);
    
    this.zHttp.guthrie('ackscan').subscribe((meshSvrRes: MeshSrvRes)=>{
      // console.log(`data = ${JSON.stringify(data)}`);

      // http://10.0.1.108:4200/8Fj8cJknEeuzhQE0IRRpeA/ackscan    // acceptable FULL html

      // http://10.0.1.108:4200/xPGtQJlIEeuZVJPym0Bm2A/ackscan     // dodgy html 

      // Place this into html to trigger the fullHtml state below: <meta name="keywords" content="zuant-full-html">
      this.fullHtml=(meshSvrRes.messageForIdentity.indexOf('zuant-full-html')>-1);

      this.messageForIdentity = this.domSanitizer.bypassSecurityTrustHtml(meshSvrRes.messageForIdentity);
      // this.messageForIdentity = data.messageForIdentity;
      
      this.logoImageSrc = meshSvrRes.styling ? meshSvrRes.styling.logoImageSrc : "";
      this.logoContainerBackgroundColor = meshSvrRes.styling ? meshSvrRes.styling.logoContainerBackgroundColor : "";

      // Place this into html to trigger the condition below: <meta name="keywords" content="zuant-full-html">
      // if (meshSvrRes.messageForIdentity.indexOf('zuant-full-html')>-1){
      //   // data._id
      //   const idForRoute = meshSvrRes["_id"].replace("==","");
      //   console.log(`zuant-full-html was detected, ${idForRoute}`); 
      //   this.router.navigate([`${idForRoute}/ackhtml`]);
      // }else{
      //   console.log(`zuant-full-html was NOT detected`); 
      // }


    });
    
  }
  

}


