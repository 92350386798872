
<ng-container *ngIf="!fullHtml">
    <div class="mesh-body">

        <div class="logo-div" [style.background-color]="logoContainerBackgroundColor">
            <img class="logo" src="{{logoImageSrc}}" >
        </div>


        <div class="mesh-container">

            <mat-drawer-container class="example-container" autosize>

                <div [ngClass]="'massive-header-margin'" [innerHtml]="messageForIdentity">
                    
                </div>


            </mat-drawer-container>

        </div>
        
    </div>
</ng-container>

<ng-container *ngIf="fullHtml">
           
    <div [innerHtml]="messageForIdentity"></div>
            
</ng-container>

