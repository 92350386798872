
<!-- <div class="mesh-body"> -->


<!-- <ng-container *ngIf="!fullHtml else fullHtmlStuff"> -->
<ng-container *ngIf="(!fullHtml && !willRedirectToCustomUrl)">
    
    <div class="mesh-body" >

        <div class="logo-div" [style.background-color]="logoContainerBackgroundColor">
            <img class="logo" src="{{logoImageSrc}}" >
        </div>

        <div class="mesh-container">

            <app-mesh-form-container #formContainer [meshSvr]="meshSvr"  ></app-mesh-form-container>
            
            
        </div>

    </div>
</ng-container>

<ng-container *ngIf="fullHtml">
           
    <div #factory_helper_div></div>

    <!-- <div>Immediately before the full container</div> -->
    <div #full_html_container></div>
    <!-- <div>Immediately after the full container</div> -->
            
</ng-container>

<ng-container *ngIf="willRedirectToCustomUrl">
           
    <div>...QR loading site...</div>
            
</ng-container>



    