import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import {environment} from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public displayForm = true;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }


  public guthrie(mode){
    
    const arrayOfUrlPathSegments = this.router.url.split('/');
    const pinpointRef = arrayOfUrlPathSegments[1];

    const doctoredPinpointRef = this.nginxSucks(pinpointRef);

    // console.log(`Guthrie here, pinpointRef = ${this.router.url} ${pinpointRef}`);
    // const someData="looper";
    const options = {
      withCredentials: true // Allows cookies to be read/set
    };

    // console.log(`Mungus here, apiUrl = ${environment.apiUrl} mark=${environment.mark}`);
    // return this.http.get(`${environment.apiUrl}/pinpoint/${mode}/${pinpointRef}`,options);
    return this.http.get(`${environment.apiUrl}/pinpoint/${mode}/${doctoredPinpointRef}`,options);
    
  }

  public getPinpointData(mode,pinpointRef){
    
    // const arrayOfUrlPathSegments = this.router.url.split('/');
    // const pinpointRef = arrayOfUrlPathSegments[1];
    // console.log(`getPinpointData has pinpointRef = ${pinpointRef}`);
    // const someData="looper";
    const options = {
      withCredentials: true // Allows cookies to be read/set
    };

    const doctoredPinpointRef = this.nginxSucks(pinpointRef);

    // console.log(`Mungus here, apiUrl = ${environment.apiUrl} mark=${environment.mark}`);
    // return this.http.get(`${environment.apiUrl}/pinpoint/${mode}/${pinpointRef}`,options);
    return this.http.get(`${environment.apiUrl}/pinpoint/${mode}/${doctoredPinpointRef}`,options);
    
  }

  private nginxSucks(pinpointRef){

    // nginx will remove any slash char (%2F) from any request path.  WFT. So, we need to doctor any pinpoint references where the base64 version contains a slash.
    // The server code then needs to reverse this.

    console.log(`pinpoint to doctor = ${pinpointRef}, type=${typeof pinpointRef} ${JSON.stringify(pinpointRef)}`);

    const doctoredValue = `${pinpointRef}`.replace('%2F','nginx_sks');

    console.log(`pinpoint to doctor = ${pinpointRef}, doctored value= ${doctoredValue}`);

    // var searchMask = "is";
    // var regEx = new RegExp(searchMask, "ig");
    // var replaceMask = "as";

    // var result = 'This iS IIS'.replace(regEx, replaceMask);

    return doctoredValue;

  }
  

}
