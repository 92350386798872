import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
  } from '@angular/router';
  import { Observable, of, EMPTY } from 'rxjs';
  import { take, mergeMap, catchError} from 'rxjs/operators'

  import { HttpService } from '../services/http.service';
  import { MeshSrvRes } from '../model/MeshSrvRes';


@Injectable({
  providedIn: 'root'
})


export class IdentityService implements Resolve<MeshSrvRes> {
  
  cachedServerResponse:MeshSrvRes;

  constructor(private zHttp: HttpService) {

   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {

    // const url = // your url;

    // this.zHttp.guthrie('scan').subscribe((meshSvr: MeshSrvRes)=>{

    // });
    console.log(`RESOLVER has route url: ${route.url}`);
    const pinpointRef = route.url;

    return this.zHttp.getPinpointData('scan',pinpointRef).pipe(
      catchError(error   => {
        return EMPTY
      }),
      mergeMap(meshSvrRes => {
          if (meshSvrRes) {
            // console.log(`RESOLVER returning ${meshSvrRes}`);
            // console.log(meshSvrRes);
            this.cachedServerResponse=meshSvrRes;
            return of(meshSvrRes)
            // return meshSvrRes;
          } else {
            console.log(`RESOLVER returning EMPTY`);
            return EMPTY;
          }
        })
      )
  }
}
