import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { F1Component } from './forms/f1/f1.component';
import { AckFormComponent} from './acknowledgements/ack-form/ack-form.component'
import { AckScanComponent} from './acknowledgements/ack-scan/ack-scan.component'
import { AckHtmlComponent} from './acknowledgements/ack-html/ack-html.component'
import { IdentityService } from './services/identity.service';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { MatInputModule } from '@angular/material/input';

const routes: Routes = [
  { path: ':id/ackscan', component: AckScanComponent },
  { path: ':id/ackform', component: AckFormComponent },
  { path: ':id/ackhtml', component: AckHtmlComponent },

  { path: '**', component: F1Component,
    resolve: {
      meshSvrRes: IdentityService
    }
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
