import { Injectable } from '@angular/core';
import { MeshSrvRes } from '../model/MeshSrvRes';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() { }

  public isFieldRequired=(meshSvr:MeshSrvRes,controlName:string,defaultValue:boolean)=>{
    
    try{
      
      const arrayOfMandatoryFieldnames = this.get_property_value_from_additionalConfiguration(meshSvr,'mandatoryFields');
      const arrayOfOptionalFieldnames = this.get_property_value_from_additionalConfiguration(meshSvr,'optionalFields');
      
      // console.log(`arrayOfMandatoryFieldnames=${arrayOfMandatoryFieldnames}`);
      // console.log(`arrayOfOptionalFieldnames=${arrayOfOptionalFieldnames}`);
      
      if (!!arrayOfMandatoryFieldnames && arrayOfMandatoryFieldnames.includes(controlName)) return true;
      if (!!arrayOfOptionalFieldnames && arrayOfOptionalFieldnames.includes(controlName)) return false;

      // console.log(`will return default of ${defaultValue} for ${controlName}`)
      return defaultValue;

    }
    catch(exc){
      return defaultValue;
    }
    
    return defaultValue;
    
  }

  getPrivacyStatementUrl=(meshSvr:MeshSrvRes)=>{

    // console.log(`Will getPrivacyStatementUrl`);

    // const privacyStatementUrl = this.get_property_value_from_additionalConfiguration(meshSvr,'privacyStatementUrl');
    
    try{

      const privacyStatementUrl = this.get_property_value_from_additionalConfiguration(meshSvr,'privacyStatementUrl');
      // console.log(`privacyStatementUrl=${JSON.stringify(privacyStatementUrl)}`);
      // if (!!privacyStatementUrl){
      if (!!privacyStatementUrl && privacyStatementUrl.length>0){
        const decodedUri = decodeURI(privacyStatementUrl)
        // console.log(`will return decodedUri of ${decodedUri}`);
        return decodeURI(privacyStatementUrl)
      }else{
        // console.log(`privacyStatementUrl is empty, so will fall through and default to Zuant policy url`);
      }

    }
    catch(exc){
      console.log(`Encountered nasty trying to decode privacyStatementUrl from meshSvr:${meshSvr}`);
      console.log(exc);
    }
    
    return "https://www.zuant.com/privacy-policy/"; // Default to the standard Zuant privacy statement
    
  }

  get_property_value_from_additionalConfiguration=(meshSvr:MeshSrvRes,propertyName:string)=>{

    // additionalConfiguration example looks like this >>>>>>"meshSvr.data.additionalConfiguration":"mandatoryFields:['company']"

    try{

      if (!!meshSvr.additionalConfiguration){
        
        const configWrappedInBraces = meshSvr.additionalConfiguration.startsWith('{') ? meshSvr.additionalConfiguration : `{${meshSvr.additionalConfiguration}}`
        // console.log(`configWrappedInBraces=${configWrappedInBraces}`);
        const configAsObject = JSON.parse(configWrappedInBraces);

        return configAsObject[propertyName];

      }

    }
    catch(exc)
    {
      console.log(exc)
    }

    // console.log(`get_property_value_from_additionalConfiguration will return an empty array`);
    return [];

  }



}
