import { Component, OnInit, ViewChild,ElementRef, AfterViewInit,ViewEncapsulation,ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { MatDrawer } from '@angular/material/sidenav';
import { MeshFormContainerComponent } from '../mesh-form-container/mesh-form-container.component';
import { MeshSrvRes } from '../../model/MeshSrvRes';
import { SpuriousComponent } from '../spurious/spurious.component';

@Component({
  selector: 'app-f1',
  templateUrl: './f1.component.html',
  styleUrls: ['./f1.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class F1Component implements OnInit, AfterViewInit {

  @ViewChild('formContainer') formContainer:MeshFormContainerComponent

  @ViewChild('full_html_container') fullHtmlContainer: ElementRef;
  @ViewChild('part2') part2: ElementRef;

  @ViewChild('factory_helper_div', {read: ViewContainerRef}) factoryHelperDiv;




  meshSvr:MeshSrvRes;

  messageForIdentityPart1:string="<div>htmla=Wanda222</div>";
  messageForIdentityPart2:string="<div>htmlb=Vision222</div>";

  htmla:string="<div>htmla=Wanda<div>";
  htmlb:string="htmlb=Vision</div>";


  @ViewChild('tom') tom: ElementRef
  @ViewChild('jerry') jerry: ElementRef

  fullHtml:boolean = false;
  willRedirectToCustomUrl:boolean = false;

  logoImageSrc: string = "";//"//img1.wsimg.com/isteam/ip/ca93572b-a1a1-4dc4-b52a-4486517d2e97/DSC_02141.jpg/:/";
  logoContainerBackgroundColor:string = "";//'#222C56';
  // meshSvr: MeshSrvRes;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private zHttp: HttpService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

  ngOnInit() {
  
    this.route.data.subscribe(data => {
      // console.log('f1 ngOnInit route subscription firing, meshSvr :', data.meshSvrRes);
      this.handleInitialisation(data.meshSvrRes);
    });

  }

  handleInitialisation=(meshSvr)=>{
    
    this.cacheMeshServerResponse(meshSvr);
    const didRerouteToSomewhereElsewhere = this.rerouteElsewhereIfAppropriate();
    if (!didRerouteToSomewhereElsewhere){
      this.constructLocalPage();
    }
    
  }

  cacheMeshServerResponse=(meshSvr:MeshSrvRes)=>{
    this.meshSvr = meshSvr; // Just store inbound MeshServerResponse in class variable
  }

  rerouteElsewhereIfAppropriate=()=>{
    
    let didRerouteToSomewhereElsewhere = false;
    
    if (this.meshSvr.identityIsContactable){
      // console.log(`will reroute to ack because identity is contactable`);
      const arrayOfUrlPathSegments = this.router.url.split('/');
      const pinpointRef = arrayOfUrlPathSegments[1];

      if (!!this.meshSvr.customRedirectURL){
        this.willRedirectToCustomUrl = true;
        // setTimeout(()=>{
        //   window.location.href=this.meshSvr.customRedirectURL;
        // },5000);
        window.location.href=this.meshSvr.customRedirectURL;
        didRerouteToSomewhereElsewhere = true;

      }else{
        
        this.router.navigateByUrl(`${pinpointRef}/ackscan`);
        didRerouteToSomewhereElsewhere = true;

      }

    }else{
      // console.log(`will NOT reroute to ack because identity is NOT contactable`);
      didRerouteToSomewhereElsewhere = false;
    }

    return didRerouteToSomewhereElsewhere;

  }

  constructLocalPage=()=>{
    // The presence of the following meta-tag within the html can act as a trigger for 'full html'.
    // <meta name="keywords" content="zuant-full-html">

    // If we have a 'full html' scenario then we should also have a div with an id of 
    // <div id="zuant-form-placeholder"></div>

    // Hence, if the messageForIdentity contains the string "zuant-full-html" then we need to 'bypass' the 
    // standard layout and use the entire supplied html within messageForIdentity.
    
    if (this.meshSvr.messageForIdentity.indexOf('zuant-full-html')>-1){
      this.fullPageHtmlSetup(this.meshSvr);
    }else{
      this.standardSetup(this.meshSvr);
    }

  }

  standardSetup=(meshSvr:MeshSrvRes)=>{
    this.logoImageSrc = meshSvr.styling ? meshSvr.styling.logoImageSrc : "";
    this.logoContainerBackgroundColor = meshSvr.styling ? meshSvr.styling.logoContainerBackgroundColor : "";
  }

  fullPageHtmlSetup=(meshSvr:MeshSrvRes)=>{
    this.fullHtml=true;
  }

  ngAfterViewInit(){
    // console.log(`f1 ngAfterViewInit running, will branch`);

    if (!!this.fullHtml){
      
      this.fullHtmlContainer.nativeElement.outerHTML = this.meshSvr.messageForIdentity;

      setTimeout(()=>{

        const element = document.getElementById("zuant-form-placeholder");
        console.log(`zuant-form-placeholder element=${element}`);

        if (!!element){
          this.factoryHelperDiv.clear();
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MeshFormContainerComponent);
          const componentRef = this.factoryHelperDiv.createComponent(componentFactory);
          componentRef.instance.ingestMeshServerResponse(this.meshSvr);
          element.appendChild(componentRef.location.nativeElement);
        }

      },100);

    }

  }

}
