import { Component, Input, OnInit, ViewChild,TemplateRef,ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { MatDrawer } from '@angular/material/sidenav';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { MeshSrvRes } from '../../model/MeshSrvRes';
import { SpuriousComponent } from '../spurious/spurious.component';
import { FormHelperService } from 'src/app/services/form-helper.service';

@Component({
  selector: 'app-mesh-form-container',
  templateUrl: './mesh-form-container.component.html',
  styleUrls: ['./mesh-form-container.component.css']
})
export class MeshFormContainerComponent implements OnInit {

  @ViewChild(MatDrawer) gdpr_drawer: MatDrawer;

  @ViewChild(ViewContainerRef) fmHost: ViewContainerRef;
  
  @Input() meshSvr:MeshSrvRes;

  profileForm = new FormGroup({
    pinpointId: new FormControl(''),
    identityId: new FormControl(''),
    email: new FormControl(''), // The email validator is implemented by adding 'email' to the control within the html template
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    companyname: new FormControl(''),
    mobile: new FormControl(''),
    toc: new FormControl(''),
    readPrivacy: new FormControl(''),
  });

  fullHtml:boolean=false;
  identityCookiePresent: boolean = true;
  identityIsContactable: boolean = true;
  messageForIdentity: SafeHtml = "";
  siteName: string="";

  isCompanynameRequired: boolean = false;
  formSubmitted: boolean = false;

  showConsent: boolean = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private zHttp: HttpService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private domSanitizer:DomSanitizer,
    private formHelperService:FormHelperService,
    ) { }

  ngOnInit() {
    // console.log(`mesh-form-container ngOnInit running, fullHtml=${this.fullHtml}, local meshSvr=${this.meshSvr}`);
    // console.log(this.meshSvr);
    this.fullHtml=(this.meshSvr.messageForIdentity.indexOf('zuant-full-html')>-1);
    
    if (!this.fullHtml){
      // The fullHtml mechanism will call ingestMeshServerResponse later - but for non-fullHtml we need to call setupForm now.
      this.setupForm();
    }
    
  }

  public ingestMeshServerResponse=(meshSvr:MeshSrvRes)=>{
    
    this.meshSvr=meshSvr;
    this.setupForm();
    
  }

  isFieldRequired=(controlName:string, defaultValue:boolean)=>{
    
    return this.formHelperService.isFieldRequired(this.meshSvr,controlName,defaultValue); // this.meshSvr *may* contain additionalConfiguration settings that dictate mandatory fields etc

  }

  getPrivacyStatementUrl=()=>{

    return this.formHelperService.getPrivacyStatementUrl(this.meshSvr); // this.meshSvr *may* contain additionalConfiguration settings that dictate mandatory fields etc

  }

  setupForm=()=>{

    // console.log(`the @Input meshSvr=${JSON.stringify(this.meshSvr)}`);

    this.identityCookiePresent = this.meshSvr.identityCookiePresent;
    this.identityIsContactable = this.meshSvr.identityIsContactable;
    
    this.messageForIdentity = this.domSanitizer.bypassSecurityTrustHtml(this.meshSvr.messageForIdentity);

    this.siteName = this.meshSvr.location.siteName;
    
    this.profileForm.patchValue({
      identityId: this.meshSvr.identityId, 
      pinpointId: this.meshSvr._id, 
    });
    
    document.addEventListener('keydown', (event) => {
      this.handleKeydownEventsww(event)
    });

  }

  handleKeydownEventsww(event){

    if (event.code=="Enter" && event.target.tagName=="INPUT"){
      this.moveToNextInputField(event);
    }
    
  }

  moveToNextInputField(event){
    const arrayOfInputElements = document.getElementsByTagName("input");
    
    for (var i=0;i<arrayOfInputElements.length;i++){
      const elem = arrayOfInputElements[i];
      // console.log(elem.id)
      if (event.target.id==elem.id){
        // console.log(`MATCH ${elem}`)
        if (i<arrayOfInputElements.length-1){
          arrayOfInputElements[i+1].focus();
        }
      }
    }
  }
  
  openConsentPanel(){
    this.openGDPRDrawer(true);
  }

  openPrivacyPanel(){
    this.openGDPRDrawer(false);
  }

  openGDPRDrawer(showConsent){
    this.showConsent=showConsent;
    this.gdpr_drawer.open();
    this.sendFocusToTopOfConsentPanel();
  }
  
  agree(){
    this.processConsent(true);
  }

  disagree(){
    this.processConsent(false);
  }

  processConsent(consented){
    this.profileForm.patchValue({
      toc: consented
    } )
    this.gdpr_drawer.close();
    this.putFocusOnSubmitButton();
  }
  

  closePrivacy(){
    this.profileForm.patchValue({
      readPrivacy: true
    } )
    this.showConsent=true;
    this.sendFocusToTopOfConsentPanel();
  }

  sendFocusToTopOfConsentPanel(){
    // We only shuffle the focus element around if we're NOT in fullHtml mode
    if (!this.fullHtml){
      const element = document.getElementById("consent-scrollto");
      element.scrollIntoView();
    }
  }

  putFocusOnSubmitButton(){
    // We only shuffle the focus around if we're NOT in fullHtml mode
    if (!this.fullHtml){
      const pb = document.getElementById("primarySubmit");
      // console.log(pb);
      pb.scrollIntoView();
    }
  }
  
  onSubmit() {
    // console.log(`form submitted, value:`,this.profileForm.value);

    if (!this.formSubmitted){
      this.formSubmitted=true;
      // console.log(`form was submitted`)
      // console.warn(this.profileForm.value);
      const options = {
        withCredentials: true // Allows cookies to be read/set
      };
      const uri = `${environment.apiUrl}/form` 
      this.http.post(uri, this.profileForm.value,options)
      .subscribe((meshSvrResponse:MeshSrvRes)=> {
        
        const arrayOfUrlPathSegments = this.router.url.split('/');
        const pinpointRef = arrayOfUrlPathSegments[1];
        // console.log(`MeshFormContainerComponent http.post response has meshSvrResponse  ${JSON.stringify(meshSvrResponse,null,2)}`);

        if (!!meshSvrResponse.customRedirectURL){
        
          window.location.href=meshSvrResponse.customRedirectURL;
  
        }else{
          
          // this.router.navigate([`${pinpointRef}/ackform`]); // Can't use navigate because it will urlencode the ref which is already encoded.
          this.router.navigateByUrl(`${pinpointRef}/ackform`); // navigateByUrl won't double-encode the already-encoded pinpoint ref
  
        }
        
      });
    }
  }

}
