
<!-- <mat-drawer-container [ngClass]="{'example-container': fullHtml==false}"  autosize> -->
<mat-drawer-container class="example-container" autosize >
    
    
    <ng-container *ngIf="(!fullHtml)">
        <!-- <div [ngClass]="(!identityIsContactable)?'header-message':'massive-header-margin'" innerHtml="{{messageForIdentity}}"> -->
        <div [ngClass]="(!identityIsContactable)?'header-message':'massive-header-margin'" [innerHtml]="messageForIdentity">    
        </div>
    </ng-container> 

    <!-- <ng-container *ngIf="(!fullHtml)">
        <div>UI FLAG TO INDICATE THAT THIS IS NOT fullHtml mode</div>
    </ng-container>
    <ng-container *ngIf="(!!fullHtml)">
        <div>UI FLAG TO INDICATE THAT THIS IS fullHtml mode</div>
    </ng-container> -->

    <!-- <form *ngIf="(identityIsContactable==false)" [formGroup]="profileForm" (ngSubmit)="onSubmit()"> -->
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <!-- <app-spurious caption="form finally here"></app-spurious> -->
        <div [hidden]="true">
            <label>
                IdentityId:
                <input type="text" formControlName="identityId">
        </label>
        </div>

        <div [hidden]="true">
            <label>
                PinpointId:
                <input type="text" formControlName="pinpointId">
        </label>
        </div>

        <mat-form-field appearance="fill" class="mf1">
            <mat-label>First name</mat-label>
            <input tabindex="0" matInput formControlName="firstname" autocomplete="given-name" [required]="isFieldRequired('firstname',true)" >
        </mat-form-field>

        <mat-form-field appearance="fill" class="mf1">
            <mat-label>Last name</mat-label>
            <input tabindex="0" matInput formControlName="lastname" autocomplete="family-name" [required]="isFieldRequired('lastname',true)" >
        </mat-form-field>

        <mat-form-field appearance="fill" class="mf1">
            <mat-label>Company name</mat-label>
            <input tabindex="0" matInput formControlName="companyname" autocomplete="organization" [required]="isFieldRequired('companyname',true)">
        </mat-form-field>

        <mat-form-field appearance="fill" class="mf1">
            <mat-label>Email address</mat-label>
            <input tabindex="0" matInput formControlName="email" email type="email" autocomplete="email" [required]="isFieldRequired('email',true)" >
        </mat-form-field>

        <mat-form-field appearance="fill" class="mf1">
            <mat-label>Mobile no.</mat-label>
            <input tabindex="0" matInput formControlName="mobile" type="tel" autocomplete="tel" [required]="isFieldRequired('mobile',false)">
        </mat-form-field>
    
        
        <div class="terms-text">
            <mat-checkbox tabindex="0" class="example-margin" formControlName="toc">I consent to my </mat-checkbox>
        </div>
        
        <div class="terms-link">
            <a mat-accent class="md-accent" [routerLink]="" (click)="openConsentPanel()">details being stored</a>        
        </div>

        <div class="centered">
            <button id="primarySubmit" type="button" mat-raised-button color="accent" (click)="onSubmit()" [disabled]="!profileForm.valid || !profileForm.value.toc || !!formSubmitted">Submit</button>
        </div>

        <div class="privacy-control-container">
            <mat-checkbox class="example-margin" formControlName="readPrivacy">Read Privacy</mat-checkbox>
        </div>

        <mat-drawer #gdpr_drawer class="example-sidenav" mode="side" >
            
            <div id="consent-scrollto"></div>

            <div>
                
                <p>I consent to the use of my personal data for sales, marketing and support purposes</p>
        
                <div class = "agree-container">
                    <button type="button" (click)="agree()" mat-raised-button>
                        Agree
                    </button>
                    <button type="button" (click)="disagree()" mat-raised-button>
                        Disagree
                    </button>
                </div>

                <div class="privacy-statement-container">
                    <!-- <a mat-accent class="md-accent" [routerLink]="" (click)="openPrivacyPanel()" >privacy statement</a> -->
                    <a target="_blank" [href]="getPrivacyStatementUrl()">View our privacy statement</a>
                    <p>(The privacy statement will open in a separate browser tab which will need to be closed to return to this point)</p>        
                </div>

            </div>

          </mat-drawer>

    </form>

</mat-drawer-container>
