import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface Styling {
  logoImageSrc?: string
  logoContainerBackgroundColor?: string
}

interface MeshSrvRes {
  messageForIdentity?: string
  styling?: Styling
}

@Component({
  selector: 'app-ack-html',
  templateUrl: './ack-html.component.html',
  styleUrls: ['./ack-html.component.css']
})
export class AckHtmlComponent implements OnInit {

  messageForIdentity: SafeHtml = "";
  // siteName: string="eewew";
  logoImageSrc: string = "";//"//img1.wsimg.com/isteam/ip/ca93572b-a1a1-4dc4-b52a-4486517d2e97/DSC_02141.jpg/:/";
  logoContainerBackgroundColor:string = "";//'#222C56';
  
  constructor(
    private domSanitizer:DomSanitizer,
    private http: HttpClient,
    private route: ActivatedRoute,
    private zHttp: HttpService,
    private router: Router,
    ) { }


  ngOnInit() {
    // console.log(`ack-scan ngOnInit running`);
    
    this.zHttp.guthrie('ackscan').subscribe((data: MeshSrvRes)=>{
      // console.log(`data = ${JSON.stringify(data)}`);

      // http://10.0.1.108:4200/8Fj8cJknEeuzhQE0IRRpeA/ackscan

      this.messageForIdentity = this.domSanitizer.bypassSecurityTrustHtml(data.messageForIdentity);
      // this.logoImageSrc = data.styling ? data.styling.logoImageSrc : "";
      // this.logoContainerBackgroundColor = data.styling ? data.styling.logoContainerBackgroundColor : "";




    });
    
  }

}
