import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { F1Component } from './forms/f1/f1.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AckFormComponent } from './acknowledgements/ack-form/ack-form.component';
import { AckScanComponent } from './acknowledgements/ack-scan/ack-scan.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { AckHtmlComponent } from './acknowledgements/ack-html/ack-html.component';
import { MeshFormContainerComponent } from './forms/mesh-form-container/mesh-form-container.component';
import { SpuriousComponent } from './forms/spurious/spurious.component';
import { HtmlSnippetComponent } from './forms/html-snippet/html-snippet.component';



@NgModule({
  declarations: [
    AppComponent,
    F1Component,
    AckFormComponent,
    AckScanComponent,
    DebounceClickDirective,
    AckHtmlComponent,
    MeshFormContainerComponent,
    SpuriousComponent,
    HtmlSnippetComponent,
    ],
  entryComponents: [SpuriousComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
